/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

:root {
  --content-width: 1330px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Manrope", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-black: #161616;
  --color-dark: #2d2d2d;
  --color-gray-400: #bdbdbd;
  --color-blue-100: #e8eeff;
  --color-blue-400: #235ee7;
  --color-coral-400: #f67366;
  --color-beige-400: #fff6df;


  //filter

  //  radius
  --radius-main: 4px;

  // shadow

  // gradient

  --arrow: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.125 10H16.875' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.25 4.375L16.875 10L11.25 15.625' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}