@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  position: relative;

  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;

    >* {
      margin-left: auto;
      width: 100%;
      max-width: 600px;
    }
  }

  &__content {
    margin-bottom: rem(121);

    h2 {
      margin-bottom: 20px;
      font-size: 42px;
    }

    p {
      margin-bottom: rem(35);
    }
  }

  &__box {
    margin-bottom: 32px;
    width: 100%;
    max-width: 370px;
  }

  &__image {
    translate: 55px;
  }

  &__footer {
    display: flex;
    align-items: flex-end;

    .contact__box {
      margin-right: 10px;
      margin-bottom: 0;
      width: 100%;
      max-width: 270px;
    }

    .btn {
      padding: 12px !important;
      height: max-content;
      max-width: 90px;
      font-weight: var(--fw-500);
      font-size: 18px;
    }
  }

  h2 {
    color: var(--color-blue-400);
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: var(--fw-700);
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: var(--color-blue-400);
  }

  input,
  textarea {
    border: 1px solid rgba(#dcca87, 0.32);
    border-radius: var(--radius-main);
    padding: 12px 18px;
    width: 100%;
    font-size: 18px;
    color: #32323299;
    background-color: transparent;
  }

  textarea {
    height: 170px;
    resize: none;
  }

  @include media(mobile-l) {
    &__content {
      margin: 0 auto rem(70);
      padding: rem(40) 0;
      max-width: 90%;
    }

    &__box,
    .contact__footer .contact__box {
      max-width: 100%;
    }

    &__image {
      display: none;
    }
  }
}