@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.insights {
  padding: rem(87) 0;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  h2 {
    margin-bottom: rem(59);
  }

  .card {
    img {
      aspect-ratio: 12 / 6.07;
    }

    &__content {
      position: relative;
    }
  }

  @include media(mobile-l) {
    padding-top: rem(40);

    &__grid {
      grid-template-columns: 1fr;
    }

    .card {
      img {
        object-position: top;
        aspect-ratio: 3 / 1;
      }
    }
  }
}