@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.card {
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-main);
  color: var(--color-white);

  &__content {
    position: absolute;
    bottom: 0;
    padding: rem(32);
    padding-bottom: rem(35);

    &::before {
      content: "";
      position: absolute;
      background-color: var(--color-black);
      opacity: 0.8;
      inset: 0;
    }

    * {
      position: relative;
    }

    h3 {
      margin-bottom: 16px;
      font-size: 24px;
    }

    p {
      margin-bottom: rem(34);
    }
  }

  img {
    width: 100%;
  }

  a {
    position: relative;
    letter-spacing: 0.02em;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      background-image: var(--arrow);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @include media(tablet) {
    &__content {
      padding: 20px;
    }
  }
}