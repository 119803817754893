@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding: rem(60) 0;

  &__content {
    margin-bottom: rem(48);
  }

  h1 {
    position: relative;
    margin-bottom: rem(40);
    text-align: left;
  }

  h2 {
    margin-bottom: 24px;
    font-weight: var(--fw-600);
    font-size: 28px;
    line-height: 1.3;
    text-align: left;
  }

  ul {
    margin-bottom: 24px;
    padding-left: 15px;
  }

  li {
    position: relative;
    padding-left: 5px;
    font-size: 20px;
    line-height: 1.43;

    &::before {
      content: "✓";
      position: absolute;
      left: -18px;
    }

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.2;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  a {
    text-decoration: underline;
  }

  b,
  strong {
    font-weight: var(--fw-600);
  }

  @include media(mobile-l) {
    .container {
      padding: 40px 0;
    }

    h1 {
      margin-bottom: 30px;
      text-align: center;
    }
  }
}