@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
  transition-property: background-color, transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &.scroll {
    background-color: var(--color-white);
  }

  &.hide.scroll {
    transform: translateY(0);
  }
}