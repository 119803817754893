@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  color: var(--color-white);
  background-color: var(--color-black);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(74) 0 rem(54);

    .logo {
      margin-bottom: rem(37);
      max-width: 672px;
    }

    a {
      font-size: 20px;
    }

    .btn {
      padding: 21px !important;
    }
  }

  &__bottom {
    border-top: 1px solid #FFFFFF33;
    padding: 23px 0;
  }

  &__wrapp {
    display: flex;
    justify-content: space-between;
  }

  .nav {
    &__item {
      &:not(:last-child) {
        margin-right: rem(52);
      }
    }

    &__link {
      padding: 0;
      text-transform: none;
      color: var(--color-white);
    }
  }

  .copyright {
    font-size: 16px;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    max-width: 337px;
    translate: 0 -9px;

    button.formsapp-button {
      max-width: 166px;
    }
  }

  @include media(tablet-wide) {
    .logo {
      max-width: 260px;
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column;
      padding: rem(30) 0;
    }

    &__rigth {
      margin-bottom: 20px;
    }

    &__wrapp {
      flex-direction: column;
      align-items: center;

      .nav {
        margin: 0 auto;
      }
    }

    .copyright {
      margin-bottom: 20px;
      text-align: center;
    }

    .logo {
      margin: 0 auto 20px;
    }

    .btns {
      translate: 0;
    }
  }
}