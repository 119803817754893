@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.single {
  padding-block: rem(102) rem(34);

  * {
    margin-bottom: rem(48);
  }

  .container {
    max-width: 1230px;
  }

  &__top {
    margin-bottom: rem(83);
  }

  .back {
    margin-bottom: rem(36);
  }

  .desc {
    font-size: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--color-blue-400);
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 24px;
    font-size: 28px;
  }

  >img {
    margin: 0 auto rem(88);
  }

  p {
    font-size: 18px;
  }

  @include media(tablet) {
    padding-top: rem(40);

    &__top {
      margin-bottom: rem(40);
    }

    >img {
      margin: 0 auto rem(40);
    }

    .desc {
      font-size: 18px;
    }

    h1 {
      line-height: 1.2;
    }
  }
}