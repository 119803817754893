@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.list-section {
  padding: rem(85) 0;

  h2 {
    margin-bottom: rem(60);
  }

  ul {
    counter-reset: list;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  li {
    counter-increment: list;
    position: relative;
    border-left: 1px solid var(--color-white);
    padding: 2px rem(28) rem(40) rem(28);

    &::before {
      content: counter(list, decimal-leading-zero);
      display: block;
      margin-bottom: 16px;
      font-weight: var(--fw-600);
      font-size: 89px;
    }

    h3 {
      margin-bottom: 22px;
    }

    p {
      width: 95%;
    }
  }

  &--simple {
    li {
      padding-top: rem(42);

      &::before {
        display: none;
      }
    }
  }

  @include media(tablet) {
    padding-bottom: rem(50);

    ul {
      display: flex;
      flex-direction: column;

      li {
        border-top: 2px solid var(--color-white);
        border-left-width: 0;

        &::before {
          font-size: 68px;
        }
      }
    }
  }

  @include media(mobile-m) {
    li {
      padding: 0 20px 20px;

      &::before {
        font-size: 56px;
      }
    }
  }
}