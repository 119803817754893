@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.elevate {
  padding: rem(86) 0;

  h2 {
    margin-bottom: rem(60);
  }

  ul {
    overflow: hidden;
    border-radius: var(--radius-main);
  }

  li {
    position: relative;
    padding: rem(40) rem(29);

    &::before {
      content: "";
      position: absolute;
      background-color: #fff;
      inset: 0;
    }

    h3 {
      margin-bottom: 23px;
    }

    p {
      width: 90%;
    }

    &:nth-child(1)::before {
      opacity: 0.2;
    }

    &:nth-child(2)::before {
      opacity: 0.1;
    }

    &:nth-child(3)::before {
      opacity: 0.05;
    }
  }

  a {
    position: absolute;
    inset: 0;

    &::after {
      content: "";
      position: absolute;
      right: rem(30);
      top: 50%;
      display: block;
      width: 60px;
      height: 60px;
      background-image: var(--arrow);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      translate: 0 -50%;
    }
  }

  @include media(tablet) {
    padding-top: rem(50);

    a {
      &::after {
        width: 40px;
        height: 40px;
      }
    }
  }

  @include media(mobile-m) {
    li {
      padding: 20px;
    }

    a {
      &::after {
        right: 10px;
      }
    }
  }
}