@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.advance {
  $root: &;

  padding: rem(119) 0;

  &__inner {
    display: flex;
  }

  &__content {
    width: 100%;
    max-width: 730px;

    h2 {
      margin-bottom: 18px;
    }

    >p:not(.slogan) {
      margin-bottom: rem(50);
      font-size: 22px;
    }
  }

  &__image {
    margin-left: auto;
    width: 100%;
    max-width: 540px;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  li {
    position: relative;
    overflow: hidden;
    border-radius: var(--radius-main);
    padding: 24px 0 20px;
    padding-left: rem(90);
    color: var(--color-white);
    background-color: var(--color-black);

    &::before {
      content: "";
      position: absolute;
      left: 33px;
      top: 50%;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.9578 5.87794L10.4177 18.417C10.2726 18.5621 10.1004 18.6773 9.91078 18.7558C9.72118 18.8344 9.51797 18.8748 9.31275 18.8748C9.10753 18.8748 8.90432 18.8344 8.71473 18.7558C8.52513 18.6773 8.35287 18.5621 8.20777 18.417L1.21363 11.3858C0.921126 11.0928 0.756836 10.6957 0.756836 10.2818C0.756836 9.86777 0.921126 9.4707 1.21363 9.17775L3.55738 6.834C3.8494 6.5419 4.24519 6.37731 4.65822 6.37622C5.07125 6.37512 5.46792 6.5376 5.76148 6.82814L9.33961 10.2725L9.35035 10.2832L18.406 1.36036C18.6989 1.06875 19.0953 0.905029 19.5086 0.905029C19.9218 0.905029 20.3183 1.06875 20.6111 1.36036L22.9548 3.66115C23.1012 3.80617 23.2174 3.97871 23.2968 4.16883C23.3762 4.35895 23.4172 4.5629 23.4175 4.76893C23.4178 4.97497 23.3773 5.17903 23.2984 5.36935C23.2195 5.55968 23.1037 5.73253 22.9578 5.87794Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      translate: 0 -55%;
    }

    h3 {
      margin-bottom: 7px;
      font-size: 20px;
      letter-spacing: 0.05em;
    }

    p {
      font-weight: var(--fw-400);
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &--number {
    li {
      counter-increment: number;

      &::before {
        content: counter(number, decimal-leading-zero);
        left: 22px;
        top: 34px;
        font-size: 39px;
        background-image: none;
      }
    }
  }

  &--services {
    padding: 0;

    #{$root}__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 15px;
      margin-left: auto;
      max-width: 675px;

      h2 {
        margin-bottom: 30px;
      }

      >p {
        margin-bottom: rem(30);
      }

      ul {
        margin-bottom: rem(40);
      }

      li {
        padding-left: rem(74);
        color: var(--color-blue-400);
        background-color: transparent;

        &::before {
          left: 18px;
          filter: brightness(0) saturate(100%) invert(27%) sepia(100%) saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
        }
      }

      .btn {
        max-width: 194px;
        color: var(--color-white);
        background-color: var(--color-blue-400);
      }
    }

    #{$root}__image {
      width: 100%;
      max-width: 653px;
      margin-left: auto;
      margin-right: 0;
    }
  }

  &--reverse {
    #{$root}__inner {
      flex-direction: row-reverse;
    }

    #{$root}__content {
      padding-right: 0;
      padding-left: 10px;
      margin-right: auto;
    }

    #{$root}__image {
      margin-right: auto;
      margin-left: 0;
    }
  }

  @include media(desktop) {

    &--reverse #{$root}__inner,
    &__inner {
      flex-direction: column;
      align-items: center;
    }

    #{$root}__content {
      margin-bottom: 40px;
    }

    &--services #{$root}__content {
      padding: 30px 20px;
      margin: 0 auto rem(40);
    }

    #{$root}__image {
      margin: 0 auto;
    }
  }

  @include media(tablet) {
    padding-top: rem(40);
    padding-bottom: rem(0);
  }

  @include media(mobile-m) {
    ul {
      grid-template-columns: 1fr;
    }
  }
}