@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.transformations {
  padding: rem(120) 0;

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__item {
    .card {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  h2 {
    margin-bottom: rem(47);
    width: 90%;
  }

  .btn {
    margin-bottom: rem(63);
    max-width: 330px;

    &::after {
      filter: brightness(0) saturate(100%) invert(27%) sepia(100%) saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
    }
  }

  @include media(mobile-l) {
    padding-block: rem(50);

    &__inner {
      grid-template-columns: 1fr;

      img {
        object-position: top;
        aspect-ratio: 16 / 10;
      }
    }
  }

  @include media(mobile-m) {
    &__inner {
      img {
        aspect-ratio: 1;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}