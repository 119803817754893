@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  line-height: 1.3;
}

h1 {
  font-weight: var(--fw-500);
  font-size: clamp(2.375rem, 1.8643rem + 2.0952vi, 3.75rem);
  text-transform: uppercase;
  letter-spacing: 0.02em;

  @include media(desktop-wide, min) {
    font-size: 60px;
  }
}

h2,
.title {
  font-weight: var(--fw-700);
  font-size: clamp(1.4375rem, 1.0893rem + 1.4286vi, 2.375rem);
}

h3 {
  font-weight: var(--fw-600);
  font-size: 28px;
}

p {
  font-size: 16px;
}