@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;

  display: flex;
  justify-content: center;

  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    gap: rem(30);
  }

  &__content {
    width: 100%;
    max-width: 880px;
  }

  &__wrapp {
    margin-bottom: rem(87);
    padding-left: rem(120);

    h1 {
      margin-bottom: rem(48);
    }
  }

  &__images {
    display: flex;

    img {
      flex-shrink: 1;
    }
  }

  &__image {
    width: 100%;
    max-width: 653px;
  }

  button.formsapp-popup-button {
    padding: 19px !important;
    max-width: 193px;
  }

  .slogan {
    font-size: 28px;
  }

  &--simple {
    #{$root}__inner {
      align-items: center;
    }

    #{$root}__content {
      margin-bottom: rem(82);
    }
  }

  &--services {
    #{$root}__wrapp {

      margin-bottom: rem(165);
    }
  }


  @include media(desktop) {
    &__wrapp {
      padding-left: rem(60);
    }
  }

  @include media(tablet) {
    &__inner {
      flex-direction: column;
    }

    &__content {
      padding-top: rem(70);
      max-width: 100%;
    }

    &__image {
      display: none;
    }

    &--simple {
      #{$root}__content {
        margin-bottom: 0;
      }

      #{$root}__wrapp {
        padding-inline: 20px;
      }
    }
  }

  @include media(mobile-m) {
    &__wrapp {
      padding-inline: 30px;
    }

    &__content {
      padding-top: rem(40);

      .slogan {
        font-size: 22px;
      }
    }
  }
}