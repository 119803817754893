@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.testimonials {
  padding: rem(60) 0;

  h2 {
    position: relative;
    margin-bottom: rem(60);
    width: 100%;
    font-weight: var(--fw-500);
    font-size: 60px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    &::after {
      content: "";
      position: absolute;
      top: 9px;
      right: 0;
      width: 60px;
      height: 60px;
      background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.1875 16.875V37.5C27.1844 40.4827 25.9981 43.3424 23.889 45.4515C21.7799 47.5606 18.9202 48.7469 15.9375 48.75C15.4402 48.75 14.9633 48.5525 14.6117 48.2008C14.26 47.8492 14.0625 47.3723 14.0625 46.875C14.0625 46.3777 14.26 45.9008 14.6117 45.5492C14.9633 45.1975 15.4402 45 15.9375 45C17.9266 45 19.8343 44.2098 21.2408 42.8033C22.6473 41.3968 23.4375 39.4891 23.4375 37.5V35.625H9.375C8.38044 35.625 7.42661 35.2299 6.72335 34.5266C6.02009 33.8234 5.625 32.8696 5.625 31.875V16.875C5.625 15.8804 6.02009 14.9266 6.72335 14.2233C7.42661 13.5201 8.38044 13.125 9.375 13.125H23.4375C24.4321 13.125 25.3859 13.5201 26.0891 14.2233C26.7924 14.9266 27.1875 15.8804 27.1875 16.875ZM50.625 13.125H36.5625C35.5679 13.125 34.6141 13.5201 33.9109 14.2233C33.2076 14.9266 32.8125 15.8804 32.8125 16.875V31.875C32.8125 32.8696 33.2076 33.8234 33.9109 34.5266C34.6141 35.2299 35.5679 35.625 36.5625 35.625H50.625V37.5C50.625 39.4891 49.8348 41.3968 48.4283 42.8033C47.0218 44.2098 45.1141 45 43.125 45C42.6277 45 42.1508 45.1975 41.7992 45.5492C41.4475 45.9008 41.25 46.3777 41.25 46.875C41.25 47.3723 41.4475 47.8492 41.7992 48.2008C42.1508 48.5525 42.6277 48.75 43.125 48.75C46.1077 48.7469 48.9674 47.5606 51.0765 45.4515C53.1856 43.3424 54.3719 40.4827 54.375 37.5V16.875C54.375 15.8804 53.9799 14.9266 53.2766 14.2233C52.5734 13.5201 51.6196 13.125 50.625 13.125Z' fill='%23235EE7'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  li {
    border-top: 1px solid var(--color-gray-400);
    padding: rem(38) rem(24);

    blockquote {
      margin-bottom: 30px;
      font-size: 24px;
    }

    cite {
      display: flex;
      align-items: center;
    }

    img {
      &:first-child {
        margin-right: 24px;
      }

      &:last-child {
        margin-left: auto;
      }
    }

    span {
      font-weight: var(--fw-600);
      font-size: 22px;
      text-align: center;
    }
  }

  @include media(tablet) {
    h2 {
      font-size: 52px;
    }

    li {
      padding: 20px;

      img:first-child {
        margin-right: 10px;
      }
    }
  }

  @include media(mobile-l) {
    h2 {
      font-size: 42px;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}