@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.about {
  $root: &;

  display: flex;
  justify-content: center;

  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    gap: rem(30);
  }

  &__content {
    padding-bottom: rem(180);
    padding-left: rem(55);
    width: 100%;
    max-width: 755px;

    h2 {
      margin-bottom: rem(40);
    }

    >p:not(.slogan) {
      width: 97%;
      font-size: 22px;
    }
  }

  &__image {
    margin-left: auto;
    width: 100%;
    max-width: 653px;
  }

  &__count {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 20px;
      top: 0;
      height: 80%;
      z-index: 0;
      width: 2px;
      background: linear-gradient(180deg, #235ee7 0%, #5488ff 52%, #aec6ff 100%);

    }

    li {
      counter-increment: list;
      position: relative;
      padding-left: rem(72);
      max-width: 600px;

      &::before {
        content: counter(list);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: var(--fw-600);
        font-size: 20px;
        left: 0;
        color: var(--color-white);
        position: absolute;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        z-index: 1;
      }


      &:not(:last-child) {
        margin-bottom: rem(62);
      }


      &:nth-child(1) {
        &::before {
          background-color: var(--color-blue-400);
        }

        h3 {
          color: var(--color-blue-400);
        }
      }

      &:nth-child(2) {
        &::before {
          background-color: #5488ff;
        }

        h3 {
          color: #5488ff;
        }
      }

      &:nth-child(3) {
        &::before {
          background-color: #87a9fa;
        }

        h3 {
          color: #87a9fa;
        }
      }
    }

    h3 {
      margin-bottom: 18px;
      font-size: 22px;
    }

    p {
      line-height: 1.6;
    }
  }

  &--reverse {
    #{$root}__inner {
      flex-direction: row-reverse;
    }

    #{$root}__content {
      padding-left: 17px;
      padding-bottom: rem(124);
      max-width: 684px;
    }

    #{$root}__image {
      margin-left: 0;
      translate: -35px;
    }
  }

  &--advance {
    #{$root}__inner {
      align-items: flex-start;
    }


    #{$root}__content {
      padding-left: rem(30);
      padding-top: rem(82);
      padding-bottom: rem(88);

      h2 {
        margin-bottom: rem(60);
      }
    }

    #{$root}__image {
      margin: 0;
      max-width: 605px;
      translate: 25px;
    }
  }

  &--portfolio {
    #{$root}__image {
      max-width: 653px;
      translate: -37px;
    }

    #{$root}__content {
      padding-left: 18px;
    }
  }

  &--review {
    #{$root}__content {
      align-self: center;
      padding-bottom: 0;
      margin-bottom: 13px;
    }

    .testimonials {
      padding: 0;
      padding-top: rem(70);

      li {
        padding: 30px 0;
      }
    }
  }

  @include media(desktop) {

    &--reverse,
    &--advance {
      #{$root}__inner {
        align-items: center;
        flex-direction: column;
      }

      #{$root}__content {
        padding-top: 0;
        padding-inline: 20px;
        padding-bottom: rem(40);
      }

      #{$root}__image {
        margin: 0 auto;
        translate: 0;
      }
    }

    &--advance {
      li {
        max-width: 100%;
      }
    }
  }

  @include media(tablet) {
    &__inner {
      padding: rem(60) 0;
      flex-direction: column;
    }

    &__content {
      padding-bottom: rem(40);
      max-width: 100%;
    }

    &__image {
      margin: 0 auto;

      img {
        object-position: center;
        aspect-ratio: 16 / 10;
      }
    }
  }

  @include media(mobile-m) {
    &__content {
      padding-inline: 30px;
    }
  }
}