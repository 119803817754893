@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: 100dvh;
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-dark);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.main {
  padding-top: var(--header-height);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  overflow-x: hidden;
  min-height: var(--vh);
}

.logo {
  display: block;
  margin-right: rem(39);
  width: 100%;
  max-width: 237px;

  img {
    width: 100%;
  }
}

.background {
  color: var(--color-white);
  background-color: var(--color-black);

  .slogan {
    color: var(--color-white);
  }
}

.back {
  position: relative;
  display: block;
  padding-left: 28px;
  color: var(--color-blue-400);
  font-weight: var(--fw-600);
  font-size: 16px;

  &::after {
    content: "";
    position: absolute;
    left: -8px;
    top: 3px;
    flex-shrink: 0;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    background-image: var(--arrow);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    scale: -1 1;
    filter: brightness(0) saturate(100%) invert(27%) sepia(100%) saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
  }
}

.slogan {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding-left: rem(67);
  font-weight: var(--fw-400);
  font-size: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--color-blue-400);

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 48px;
    height: 1px;
    background-color: currentColor;
    translate: 0 -50%;
  }
}

.decor {
  position: absolute;
  width: 202px;
  height: 202px;
  background-color: var(--color-blue-400);
  filter: blur(250px);
}