@import "https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap";
:root {
  --content-width: 1330px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Manrope", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #161616;
  --color-dark: #2d2d2d;
  --color-gray-400: #bdbdbd;
  --color-blue-100: #e8eeff;
  --color-blue-400: #235ee7;
  --color-coral-400: #f67366;
  --color-beige-400: #fff6df;
  --radius-main: 4px;
  --arrow: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.125 10H16.875' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.25 4.375L16.875 10L11.25 15.625' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: 100dvh;
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-dark);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.main {
  padding-top: var(--header-height);
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
  overflow-x: hidden;
}

.logo {
  width: 100%;
  max-width: 237px;
  margin-right: 2.4375rem;
  display: block;
}

.logo img {
  width: 100%;
}

.background {
  color: var(--color-white);
  background-color: var(--color-black);
}

.background .slogan {
  color: var(--color-white);
}

.back {
  color: var(--color-blue-400);
  font-weight: var(--fw-600);
  padding-left: 28px;
  font-size: 16px;
  display: block;
  position: relative;
}

.back:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--arrow);
  filter: brightness(0) saturate() invert(27%) sepia() saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  margin-left: 8px;
  position: absolute;
  top: 3px;
  left: -8px;
  scale: -1 1;
}

.slogan {
  font-weight: var(--fw-400);
  letter-spacing: .02em;
  text-transform: uppercase;
  color: var(--color-blue-400);
  margin-bottom: 20px;
  padding-left: 4.1875rem;
  font-size: 24px;
  display: block;
  position: relative;
}

.slogan:before {
  content: "";
  width: 48px;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  left: 0;
  translate: 0 -50%;
}

.decor {
  width: 202px;
  height: 202px;
  background-color: var(--color-blue-400);
  filter: blur(250px);
  position: absolute;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.3;
  overflow: hidden;
}

h1 {
  font-weight: var(--fw-500);
  text-transform: uppercase;
  letter-spacing: .02em;
  font-size: clamp(2.375rem, 1.8643rem + 2.0952vi, 3.75rem);
}

@media only screen and (min-width: 1441px) {
  h1 {
    font-size: 60px;
  }
}

h2, .title {
  font-weight: var(--fw-700);
  font-size: clamp(1.4375rem, 1.0893rem + 1.4286vi, 2.375rem);
}

h3 {
  font-weight: var(--fw-600);
  font-size: 28px;
}

p {
  font-size: 16px;
}

.header {
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  transition-property: background-color, transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header.scroll {
  background-color: var(--color-white);
}

.header.hide.scroll {
  transform: translateY(0);
}

.nav {
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    max-width: 340px;
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    padding-inline: 10px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__item:not(:last-child) {
  margin-right: 13px;
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .header .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-weight: var(--fw-600);
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--color-black);
  background-color: #0000;
  padding: 0 10px;
  font-size: 16px;
  transition: color .2s;
}

.nav__link:hover, .nav__link:focus, .nav__link.true {
  color: var(--color-blue-400);
}

.nav__btns {
  width: 100%;
  max-width: 337px;
  justify-content: space-between;
  display: flex;
}

.nav__btns button.formsapp-popup-button {
  max-width: 165px;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

.nav--footer {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    margin: 0 auto;
  }

  .nav__btns {
    align-items: center;
  }

  .nav button.formsapp-popup-button {
    padding: 20px 0 !important;
  }
}

[class].btn {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: 152px;
  font-weight: var(--fw-600);
  text-align: center;
  color: var(--color-blue-400);
  background-color: var(--color-blue-100);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.36;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
  padding: 18px !important;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-blue-400);
  background-color: var(--color-white);
}

[class].btn:hover:after, [class].btn:focus:after {
  filter: brightness(0) saturate() invert(27%) sepia() saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
}

[class].btn--reverse {
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  border-color: var(--color-blue-400);
  color: var(--color-blue-400);
  background-color: var(--color-white);
}

[class].btn--dark {
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

[class].btn--arrow:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  margin-left: 8px;
}

button.formsapp-popup-button {
  white-space: nowrap;
  border-radius: var(--radius-main) !important;
  font-family: var(--font-family-primary) !important;
  color: var(--color-white) !important;
  background-color: var(--color-blue-400) !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1 !important;
}

button.formsapp-popup-button:hover, button.formsapp-popup-button:focus {
  border-color: var(--color-blue-400) !important;
  color: var(--color-blue-400) !important;
  background-color: var(--color-white) !important;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .1s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  padding: 3.75rem 0;
}

.policy__content {
  margin-bottom: 3rem;
}

.policy h1 {
  text-align: left;
  margin-bottom: 2.5rem;
  position: relative;
}

.policy h2 {
  font-weight: var(--fw-600);
  text-align: left;
  margin-bottom: 24px;
  font-size: 28px;
  line-height: 1.3;
}

.policy ul {
  margin-bottom: 24px;
  padding-left: 15px;
}

.policy li {
  padding-left: 5px;
  font-size: 20px;
  line-height: 1.43;
  position: relative;
}

.policy li:before {
  content: "✓";
  position: absolute;
  left: -18px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy p {
  font-size: 18px;
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 30px;
}

.policy a {
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 768px) {
  .policy .container {
    padding: 40px 0;
  }

  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer {
  color: var(--color-white);
  background-color: var(--color-black);
}

.footer__inner {
  justify-content: space-between;
  align-items: center;
  padding: 4.625rem 0 3.375rem;
  display: flex;
}

.footer__inner .logo {
  max-width: 672px;
  margin-bottom: 2.3125rem;
}

.footer__inner a {
  font-size: 20px;
}

.footer__inner .btn {
  padding: 21px !important;
}

.footer__bottom {
  border-top: 1px solid #fff3;
  padding: 23px 0;
}

.footer__wrapp {
  justify-content: space-between;
  display: flex;
}

.footer .nav__item:not(:last-child) {
  margin-right: 3.25rem;
}

.footer .nav__link {
  text-transform: none;
  color: var(--color-white);
  padding: 0;
}

.footer .copyright {
  font-size: 16px;
}

.footer .btns {
  width: 100%;
  height: 60px;
  max-width: 337px;
  justify-content: space-between;
  display: flex;
  translate: 0 -9px;
}

.footer .btns button.formsapp-button {
  max-width: 166px;
}

@media only screen and (max-width: 1180px) {
  .footer .logo {
    max-width: 260px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__inner {
    flex-direction: column;
    padding: 1.875rem 0;
  }

  .footer__rigth {
    margin-bottom: 20px;
  }

  .footer__wrapp {
    flex-direction: column;
    align-items: center;
  }

  .footer__wrapp .nav {
    margin: 0 auto;
  }

  .footer .copyright {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer .logo {
    margin: 0 auto 20px;
  }

  .footer .btns {
    translate: 0;
  }
}

.hero {
  justify-content: center;
  display: flex;
}

.hero__inner {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  gap: 1.875rem;
  display: flex;
}

.hero__content {
  width: 100%;
  max-width: 880px;
}

.hero__wrapp {
  margin-bottom: 5.4375rem;
  padding-left: 7.5rem;
}

.hero__wrapp h1 {
  margin-bottom: 3rem;
}

.hero__images {
  display: flex;
}

.hero__images img {
  flex-shrink: 1;
}

.hero__image {
  width: 100%;
  max-width: 653px;
}

.hero button.formsapp-popup-button {
  max-width: 193px;
  padding: 19px !important;
}

.hero .slogan {
  font-size: 28px;
}

.hero--simple .hero__inner {
  align-items: center;
}

.hero--simple .hero__content {
  margin-bottom: 5.125rem;
}

.hero--services .hero__wrapp {
  margin-bottom: 10.3125rem;
}

@media only screen and (max-width: 1280px) {
  .hero__wrapp {
    padding-left: 3.75rem;
  }
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    flex-direction: column;
  }

  .hero__content {
    max-width: 100%;
    padding-top: 4.375rem;
  }

  .hero__image {
    display: none;
  }

  .hero--simple .hero__content {
    margin-bottom: 0;
  }

  .hero--simple .hero__wrapp {
    padding-inline: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .hero__wrapp {
    padding-inline: 30px;
  }

  .hero__content {
    padding-top: 2.5rem;
  }

  .hero__content .slogan {
    font-size: 22px;
  }
}

.contact {
  position: relative;
}

.contact__inner {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.contact__inner > * {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
}

.contact__content {
  margin-bottom: 7.5625rem;
}

.contact__content h2 {
  margin-bottom: 20px;
  font-size: 42px;
}

.contact__content p {
  margin-bottom: 2.1875rem;
}

.contact__box {
  width: 100%;
  max-width: 370px;
  margin-bottom: 32px;
}

.contact__image {
  translate: 55px;
}

.contact__footer {
  align-items: flex-end;
  display: flex;
}

.contact__footer .contact__box {
  width: 100%;
  max-width: 270px;
  margin-bottom: 0;
  margin-right: 10px;
}

.contact__footer .btn {
  height: max-content;
  max-width: 90px;
  font-weight: var(--fw-500);
  font-size: 18px;
  padding: 12px !important;
}

.contact h2 {
  color: var(--color-blue-400);
}

.contact label {
  font-weight: var(--fw-700);
  letter-spacing: .15em;
  text-transform: uppercase;
  color: var(--color-blue-400);
  margin-bottom: 10px;
  font-size: 14px;
  display: block;
}

.contact input, .contact textarea {
  border-radius: var(--radius-main);
  width: 100%;
  color: #32323299;
  background-color: #0000;
  border: 1px solid #dcca8752;
  padding: 12px 18px;
  font-size: 18px;
}

.contact textarea {
  height: 170px;
  resize: none;
}

@media only screen and (max-width: 768px) {
  .contact__content {
    max-width: 90%;
    margin: 0 auto 4.375rem;
    padding: 2.5rem 0;
  }

  .contact__box, .contact .contact__footer .contact__box {
    max-width: 100%;
  }

  .contact__image {
    display: none;
  }
}

.about {
  justify-content: center;
  display: flex;
}

.about__inner {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  gap: 1.875rem;
  display: flex;
}

.about__content {
  width: 100%;
  max-width: 755px;
  padding-bottom: 11.25rem;
  padding-left: 3.4375rem;
}

.about__content h2 {
  margin-bottom: 2.5rem;
}

.about__content > p:not(.slogan) {
  width: 97%;
  font-size: 22px;
}

.about__image {
  width: 100%;
  max-width: 653px;
  margin-left: auto;
}

.about__count {
  position: relative;
}

.about__count:after {
  content: "";
  height: 80%;
  z-index: 0;
  width: 2px;
  background: linear-gradient(#235ee7 0%, #5488ff 52%, #aec6ff 100%);
  position: absolute;
  top: 0;
  left: 20px;
}

.about__count li {
  counter-increment: list;
  max-width: 600px;
  padding-left: 4.5rem;
  position: relative;
}

.about__count li:before {
  content: counter(list);
  font-weight: var(--fw-600);
  color: var(--color-white);
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: flex;
  position: absolute;
  left: 0;
}

.about__count li:not(:last-child) {
  margin-bottom: 3.875rem;
}

.about__count li:nth-child(1):before {
  background-color: var(--color-blue-400);
}

.about__count li:nth-child(1) h3 {
  color: var(--color-blue-400);
}

.about__count li:nth-child(2):before {
  background-color: #5488ff;
}

.about__count li:nth-child(2) h3 {
  color: #5488ff;
}

.about__count li:nth-child(3):before {
  background-color: #87a9fa;
}

.about__count li:nth-child(3) h3 {
  color: #87a9fa;
}

.about__count h3 {
  margin-bottom: 18px;
  font-size: 22px;
}

.about__count p {
  line-height: 1.6;
}

.about--reverse .about__inner {
  flex-direction: row-reverse;
}

.about--reverse .about__content {
  max-width: 684px;
  padding-bottom: 7.75rem;
  padding-left: 17px;
}

.about--reverse .about__image {
  margin-left: 0;
  translate: -35px;
}

.about--advance .about__inner {
  align-items: flex-start;
}

.about--advance .about__content {
  padding-top: 5.125rem;
  padding-bottom: 5.5rem;
  padding-left: 1.875rem;
}

.about--advance .about__content h2 {
  margin-bottom: 3.75rem;
}

.about--advance .about__image {
  max-width: 605px;
  margin: 0;
  translate: 25px;
}

.about--portfolio .about__image {
  max-width: 653px;
  translate: -37px;
}

.about--portfolio .about__content {
  padding-left: 18px;
}

.about--review .about__content {
  align-self: center;
  margin-bottom: 13px;
  padding-bottom: 0;
}

.about--review .testimonials {
  padding: 4.375rem 0 0;
}

.about--review .testimonials li {
  padding: 30px 0;
}

@media only screen and (max-width: 1280px) {
  .about--reverse .about__inner, .about--advance .about__inner {
    flex-direction: column;
    align-items: center;
  }

  .about--reverse .about__content, .about--advance .about__content {
    padding-top: 0;
    padding-inline: 20px;
    padding-bottom: 2.5rem;
  }

  .about--reverse .about__image, .about--advance .about__image {
    margin: 0 auto;
    translate: 0;
  }

  .about--advance li {
    max-width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .about__inner {
    flex-direction: column;
    padding: 3.75rem 0;
  }

  .about__content {
    max-width: 100%;
    padding-bottom: 2.5rem;
  }

  .about__image {
    margin: 0 auto;
  }

  .about__image img {
    object-position: center;
    aspect-ratio: 16 / 10;
  }
}

@media only screen and (max-width: 576px) {
  .about__content {
    padding-inline: 30px;
  }
}

.list-section {
  padding: 5.3125rem 0;
}

.list-section h2 {
  margin-bottom: 3.75rem;
}

.list-section ul {
  counter-reset: list;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.list-section li {
  counter-increment: list;
  border-left: 1px solid var(--color-white);
  padding: 2px 1.75rem 2.5rem;
  position: relative;
}

.list-section li:before {
  content: counter(list, decimal-leading-zero);
  font-weight: var(--fw-600);
  margin-bottom: 16px;
  font-size: 89px;
  display: block;
}

.list-section li h3 {
  margin-bottom: 22px;
}

.list-section li p {
  width: 95%;
}

.list-section--simple li {
  padding-top: 2.625rem;
}

.list-section--simple li:before {
  display: none;
}

@media only screen and (max-width: 992px) {
  .list-section {
    padding-bottom: 3.125rem;
  }

  .list-section ul {
    flex-direction: column;
    display: flex;
  }

  .list-section ul li {
    border-top: 2px solid var(--color-white);
    border-left-width: 0;
  }

  .list-section ul li:before {
    font-size: 68px;
  }
}

@media only screen and (max-width: 576px) {
  .list-section li {
    padding: 0 20px 20px;
  }

  .list-section li:before {
    font-size: 56px;
  }
}

.card {
  border-radius: var(--radius-main);
  color: var(--color-white);
  position: relative;
  overflow: hidden;
}

.card__content {
  padding: 2rem 2rem 2.1875rem;
  position: absolute;
  bottom: 0;
}

.card__content:before {
  content: "";
  background-color: var(--color-black);
  opacity: .8;
  position: absolute;
  inset: 0;
}

.card__content * {
  position: relative;
}

.card__content h3 {
  margin-bottom: 16px;
  font-size: 24px;
}

.card__content p {
  margin-bottom: 2.125rem;
}

.card img {
  width: 100%;
}

.card a {
  letter-spacing: .02em;
  position: relative;
}

.card a:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 8px;
  position: absolute;
  top: 3px;
}

@media only screen and (max-width: 992px) {
  .card__content {
    padding: 20px;
  }
}

.insights {
  padding: 5.4375rem 0;
}

.insights__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.insights h2 {
  margin-bottom: 3.6875rem;
}

.insights .card img {
  aspect-ratio: 12 / 6.07;
}

.insights .card__content {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .insights {
    padding-top: 2.5rem;
  }

  .insights__grid {
    grid-template-columns: 1fr;
  }

  .insights .card img {
    object-position: top;
    aspect-ratio: 3 / 1;
  }
}

.advance {
  padding: 7.4375rem 0;
}

.advance__inner {
  display: flex;
}

.advance__content {
  width: 100%;
  max-width: 730px;
}

.advance__content h2 {
  margin-bottom: 18px;
}

.advance__content > p:not(.slogan) {
  margin-bottom: 3.125rem;
  font-size: 22px;
}

.advance__image {
  width: 100%;
  max-width: 540px;
  margin-left: auto;
}

.advance ul {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  display: grid;
}

.advance li {
  border-radius: var(--radius-main);
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 24px 0 20px 5.625rem;
  position: relative;
  overflow: hidden;
}

.advance li:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.9578 5.87794L10.4177 18.417C10.2726 18.5621 10.1004 18.6773 9.91078 18.7558C9.72118 18.8344 9.51797 18.8748 9.31275 18.8748C9.10753 18.8748 8.90432 18.8344 8.71473 18.7558C8.52513 18.6773 8.35287 18.5621 8.20777 18.417L1.21363 11.3858C0.921126 11.0928 0.756836 10.6957 0.756836 10.2818C0.756836 9.86777 0.921126 9.4707 1.21363 9.17775L3.55738 6.834C3.8494 6.5419 4.24519 6.37731 4.65822 6.37622C5.07125 6.37512 5.46792 6.5376 5.76148 6.82814L9.33961 10.2725L9.35035 10.2832L18.406 1.36036C18.6989 1.06875 19.0953 0.905029 19.5086 0.905029C19.9218 0.905029 20.3183 1.06875 20.6111 1.36036L22.9548 3.66115C23.1012 3.80617 23.2174 3.97871 23.2968 4.16883C23.3762 4.35895 23.4172 4.5629 23.4175 4.76893C23.4178 4.97497 23.3773 5.17903 23.2984 5.36935C23.2195 5.55968 23.1037 5.73253 22.9578 5.87794Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 33px;
  translate: 0 -55%;
}

.advance li h3 {
  letter-spacing: .05em;
  margin-bottom: 7px;
  font-size: 20px;
}

.advance li p {
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 1.5;
}

.advance--number li {
  counter-increment: number;
}

.advance--number li:before {
  content: counter(number, decimal-leading-zero);
  background-image: none;
  font-size: 39px;
  top: 34px;
  left: 22px;
}

.advance--services {
  padding: 0;
}

.advance--services .advance__content {
  max-width: 675px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  padding-right: 15px;
  display: flex;
}

.advance--services .advance__content h2 {
  margin-bottom: 30px;
}

.advance--services .advance__content > p {
  margin-bottom: 1.875rem;
}

.advance--services .advance__content ul {
  margin-bottom: 2.5rem;
}

.advance--services .advance__content li {
  color: var(--color-blue-400);
  background-color: #0000;
  padding-left: 4.625rem;
}

.advance--services .advance__content li:before {
  filter: brightness(0) saturate() invert(27%) sepia() saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
  left: 18px;
}

.advance--services .advance__content .btn {
  max-width: 194px;
  color: var(--color-white);
  background-color: var(--color-blue-400);
}

.advance--services .advance__image {
  width: 100%;
  max-width: 653px;
  margin-left: auto;
  margin-right: 0;
}

.advance--reverse .advance__inner {
  flex-direction: row-reverse;
}

.advance--reverse .advance__content {
  margin-right: auto;
  padding-left: 10px;
  padding-right: 0;
}

.advance--reverse .advance__image {
  margin-left: 0;
  margin-right: auto;
}

@media only screen and (max-width: 1280px) {
  .advance--reverse .advance__inner, .advance__inner {
    flex-direction: column;
    align-items: center;
  }

  .advance .advance__content {
    margin-bottom: 40px;
  }

  .advance--services .advance__content {
    margin: 0 auto 2.5rem;
    padding: 30px 20px;
  }

  .advance .advance__image {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 992px) {
  .advance {
    padding-top: 2.5rem;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .advance ul {
    grid-template-columns: 1fr;
  }
}

.single {
  padding-block: 6.375rem 2.125rem;
}

.single * {
  margin-bottom: 3rem;
}

.single .container {
  max-width: 1230px;
}

.single__top {
  margin-bottom: 5.1875rem;
}

.single .back {
  margin-bottom: 2.25rem;
}

.single .desc {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-blue-400);
  font-size: 24px;
}

.single h1 {
  margin-bottom: 20px;
}

.single h2 {
  margin-bottom: 24px;
  font-size: 28px;
}

.single > img {
  margin: 0 auto 5.5rem;
}

.single p {
  font-size: 18px;
}

@media only screen and (max-width: 992px) {
  .single {
    padding-top: 2.5rem;
  }

  .single__top {
    margin-bottom: 2.5rem;
  }

  .single > img {
    margin: 0 auto 2.5rem;
  }

  .single .desc {
    font-size: 18px;
  }

  .single h1 {
    line-height: 1.2;
  }
}

.elevate {
  padding: 5.375rem 0;
}

.elevate h2 {
  margin-bottom: 3.75rem;
}

.elevate ul {
  border-radius: var(--radius-main);
  overflow: hidden;
}

.elevate li {
  padding: 2.5rem 1.8125rem;
  position: relative;
}

.elevate li:before {
  content: "";
  background-color: #fff;
  position: absolute;
  inset: 0;
}

.elevate li h3 {
  margin-bottom: 23px;
}

.elevate li p {
  width: 90%;
}

.elevate li:nth-child(1):before {
  opacity: .2;
}

.elevate li:nth-child(2):before {
  opacity: .1;
}

.elevate li:nth-child(3):before {
  opacity: .05;
}

.elevate a {
  position: absolute;
  inset: 0;
}

.elevate a:after {
  content: "";
  width: 60px;
  height: 60px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  top: 50%;
  right: 1.875rem;
  translate: 0 -50%;
}

@media only screen and (max-width: 992px) {
  .elevate {
    padding-top: 3.125rem;
  }

  .elevate a:after {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .elevate li {
    padding: 20px;
  }

  .elevate a:after {
    right: 10px;
  }
}

.transformations {
  padding: 7.5rem 0;
}

.transformations__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.transformations__item .card:not(:last-child) {
  margin-bottom: 25px;
}

.transformations h2 {
  width: 90%;
  margin-bottom: 2.9375rem;
}

.transformations .btn {
  max-width: 330px;
  margin-bottom: 3.9375rem;
}

.transformations .btn:after {
  filter: brightness(0) saturate() invert(27%) sepia() saturate(1596%) hue-rotate(211deg) brightness(92%) contrast(98%);
}

@media only screen and (max-width: 768px) {
  .transformations {
    padding-block: 3.125rem;
  }

  .transformations__inner {
    grid-template-columns: 1fr;
  }

  .transformations__inner img {
    object-position: top;
    aspect-ratio: 16 / 10;
  }
}

@media only screen and (max-width: 576px) {
  .transformations__inner img {
    aspect-ratio: 1;
  }

  .transformations__inner p {
    margin-bottom: 20px;
  }
}

.testimonials {
  padding: 3.75rem 0;
}

.testimonials h2 {
  width: 100%;
  font-weight: var(--fw-500);
  letter-spacing: .02em;
  text-transform: uppercase;
  margin-bottom: 3.75rem;
  font-size: 60px;
  position: relative;
}

.testimonials h2:after {
  content: "";
  width: 60px;
  height: 60px;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.1875 16.875V37.5C27.1844 40.4827 25.9981 43.3424 23.889 45.4515C21.7799 47.5606 18.9202 48.7469 15.9375 48.75C15.4402 48.75 14.9633 48.5525 14.6117 48.2008C14.26 47.8492 14.0625 47.3723 14.0625 46.875C14.0625 46.3777 14.26 45.9008 14.6117 45.5492C14.9633 45.1975 15.4402 45 15.9375 45C17.9266 45 19.8343 44.2098 21.2408 42.8033C22.6473 41.3968 23.4375 39.4891 23.4375 37.5V35.625H9.375C8.38044 35.625 7.42661 35.2299 6.72335 34.5266C6.02009 33.8234 5.625 32.8696 5.625 31.875V16.875C5.625 15.8804 6.02009 14.9266 6.72335 14.2233C7.42661 13.5201 8.38044 13.125 9.375 13.125H23.4375C24.4321 13.125 25.3859 13.5201 26.0891 14.2233C26.7924 14.9266 27.1875 15.8804 27.1875 16.875ZM50.625 13.125H36.5625C35.5679 13.125 34.6141 13.5201 33.9109 14.2233C33.2076 14.9266 32.8125 15.8804 32.8125 16.875V31.875C32.8125 32.8696 33.2076 33.8234 33.9109 34.5266C34.6141 35.2299 35.5679 35.625 36.5625 35.625H50.625V37.5C50.625 39.4891 49.8348 41.3968 48.4283 42.8033C47.0218 44.2098 45.1141 45 43.125 45C42.6277 45 42.1508 45.1975 41.7992 45.5492C41.4475 45.9008 41.25 46.3777 41.25 46.875C41.25 47.3723 41.4475 47.8492 41.7992 48.2008C42.1508 48.5525 42.6277 48.75 43.125 48.75C46.1077 48.7469 48.9674 47.5606 51.0765 45.4515C53.1856 43.3424 54.3719 40.4827 54.375 37.5V16.875C54.375 15.8804 53.9799 14.9266 53.2766 14.2233C52.5734 13.5201 51.6196 13.125 50.625 13.125Z' fill='%23235EE7'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 9px;
  right: 0;
}

.testimonials ul {
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  display: grid;
}

.testimonials li {
  border-top: 1px solid var(--color-gray-400);
  padding: 2.375rem 1.5rem;
}

.testimonials li blockquote {
  margin-bottom: 30px;
  font-size: 24px;
}

.testimonials li cite {
  align-items: center;
  display: flex;
}

.testimonials li img:first-child {
  margin-right: 24px;
}

.testimonials li img:last-child {
  margin-left: auto;
}

.testimonials li span {
  font-weight: var(--fw-600);
  text-align: center;
  font-size: 22px;
}

@media only screen and (max-width: 992px) {
  .testimonials h2 {
    font-size: 52px;
  }

  .testimonials li {
    padding: 20px;
  }

  .testimonials li img:first-child {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .testimonials h2 {
    font-size: 42px;
  }

  .testimonials ul {
    grid-template-columns: 1fr;
    display: grid;
  }
}

/*# sourceMappingURL=main.css.map */
