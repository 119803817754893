@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;

  &__inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .header & {
      @include media(tablet) {
        position: fixed;
        right: 0;
        top: 0;
        z-index: -1;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        padding-top: calc(var(--header-height) * 1.5);
        width: 100%;
        height: calc(var(--vh));
        max-width: 340px;
        background-color: var(--color-white);
        transform: translateX(150%);
        transition-property: transform;
        transition-duration: 0.4s;
        padding-inline: 10px;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: auto;

    .header & {
      @include media(tablet) {
        z-index: -1;
        flex-direction: column;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 13px;

      @include media(tablet) {
        margin-right: 0;
      }
    }

    .header & {
      @include media(tablet) {
        margin-bottom: 30px;
      }
    }
  }

  &__link {
    padding: 0 10px;
    font-weight: var(--fw-600);
    font-size: 16px;
    white-space: nowrap;
    text-transform: uppercase;
    color: var(--color-black);
    background-color: transparent;
    transition: color 0.2s;

    &:hover,
    &:focus {
      color: var(--color-blue-400);
    }

    &.true {
      color: var(--color-blue-400);
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 337px;

    button.formsapp-popup-button {
      max-width: 165px;
    }

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &--footer {
    display: flex;
  }

  @include media(tablet) {
    &__list {
      margin: 0 auto;
    }

    &__btns {
      align-items: center;
    }

    button.formsapp-popup-button {
      padding: 20px 0 !important;
    }
  }
}